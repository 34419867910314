<script setup lang="ts">
import { getGGAuthen, loginGG } from '@/apis/auth'

const route = useRoute()
const router = useRouter()
const { execute: doGetGG, data } = getGGAuthen()

const doGetGGCode = () => {
  window.open(data.value.url, '_self')
}

const isReadyJoin = computed(() => data.value?.url)
const isJoining = computed(() => !!route.query.code)

onMounted(() => {
  if (isJoining.value) {
    const { execute: doLoginGG } = loginGG(route.query.code)
    doLoginGG().then(() => {
      router.push({ name: 'page' })
    })
  } else {
    doGetGG()
  }
})
</script>

<template>
  <div
    class="relative bg-www-paage-io-join-1920x1080-default-athens-gray shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] w-full flex flex-col items-center justify-center box-border min-h-screen text-center text-sm text-www-paage-io-join-1920x1080-default-ebony font-sans pt-8"
  >
    <div
      class="box-border flex flex-col items-center justify-start w-full pb-0"
    >
      <div
        class="w-[480px] flex flex-col items-start justify-start gap-[24px] max-w-[480px]"
      >
        <div
          class="self-stretch relative rounded-lg bg-www-paage-io-join-1920x1080-default-nero shadow-[0px_1px_3px_rgba(0,_0,_0,_0.1),_0px_1px_2px_-1px_rgba(0,_0,_0,_0.1)] h-[328px] overflow-hidden shrink-0"
        >
          <div
            class="absolute top-[48px] right-[48px] left-[48px] flex flex-col items-center justify-start py-0 box-border text-5xl"
          >
            <b class="relative tracking-[-0.6px] leading-[36px] inline-block"
              >Join with your work email</b
            >
          </div>
          <div
            class="absolute top-[88px] right-[48px] left-[48px] flex flex-col items-center justify-start py-0 pr-[4.399998188018799px] pl-[4.600001811981201px] box-border text-www-paage-io-join-1920x1080-default-pale-sky"
          >
            <div class="relative leading-[24px] inline-block max-w-[384px]">
              <p class="m-0">
                No permissions are requested. We only use your email to create
                your account.
              </p>
            </div>
          </div>
          <div
            class="absolute top-[176px] left-[calc(50%_-_192px)] w-96 flex flex-col items-start justify-start gap-[16px] text-www-paage-io-join-1920x1080-default-ebony1"
          >
            <button
              class="rounded-md bg-www-paage-io-join-1920x1080-default-nero shadow-[0px_0px_0px_1px_rgba(15,_23,_42,_0.1),_0px_1px_2px_rgba(0,_0,_0,_0.05)] w-96 overflow-hidden items-center gap-4 justify-center py-3 box-border relative cursor-pointer flex transition-all hover:opacity-80"
              :disabled="!isReadyJoin"
              @click="doGetGGCode()"
            >
              <img
                v-if="!isJoining"
                class="my-0 w-6 h-6 overflow-hidden shrink-0 z-[1]"
                alt=""
                src="@/assets/JoinScreen/svg.svg"
              />
              <div
                v-if="!isJoining"
                class="relative leading-[20px] font-medium inline-block max-w-[360px] z-[0]"
              >
                Continue with Google
              </div>
              <div v-else="">
                <icon name="eos-icons:three-dots-loading" size="3em" />
              </div>
            </button>
          </div>
          <div
            class="absolute w-[calc(100%_-_96px)] top-[280px] right-[48px] left-[48px] h-px"
          >
            <img
              class="absolute h-[108000%] w-[500%] top-[-28000%] right-[-387.5%] bottom-[-79900%] left-[-12.5%] max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src="@/assets/JoinScreen/image@2x.png"
            />
          </div>
        </div>
        <div
          class="self-stretch flex flex-col items-start justify-start p-8 gap-[24px] text-left text-www-paage-io-join-1920x1080-default-ebony1"
        >
          <div
            class="self-stretch flex flex-col items-start justify-start py-0 pr-1.5 pl-0 gap-[24px]"
          >
            <div class="relative leading-[24px] inline-block max-w-[416px]">
              Greetings! 👋
            </div>
            <div class="relative leading-[24px] inline-block max-w-[416px]">
              I'm Nam, the founder of 1Page, and I'm thrilled to have you here.
              Our mission at 1Page is to enhance your business documents,
              ensuring everyone stays on the same page in a straightforward and
              sophisticated manner, ultimately improving life for all.
            </div>
            <div class="relative leading-[24px] inline-block max-w-[416px]">
              If you're curious about why 1Page is the simplest solution for B2B
              sales teams to embrace data-driven practices, consider booking a
              demo with us!
            </div>
            <div class="relative leading-[24px] inline-block max-w-[416px]">
              We're in the early stages, so if you happen to encounter any
              adorable bugs (functions that aren't quite functioning) or if you
              have ideas on how we can tailor 1Page to better suit your needs,
              please share your feedback with us
            </div>
            <div class="relative leading-[24px] inline-block max-w-[416px]">
              A special shout-out to our founding engineers, Jack and <a class="font-medium text-primary hover:underline" href="https://kienkhang.vercel.app/" target="_blank">Khang</a>, for
              their hard work—many more milestones to come!
            </div>
            <div class="relative leading-[24px] inline-block max-w-[416px]">
              Thank you, and I can't wait to witness your success with 1Page.
            </div>
          </div>
          <div
            class="self-stretch flex flex-row items-center justify-start py-0 pr-[237px] pl-0 gap-[16px] text-www-paage-io-join-1920x1080-default-ebony"
          >
            <img
              class="relative rounded-9980xl w-10 h-10 overflow-hidden shrink-0 object-cover max-w-[416px]"
              alt=""
              src="@/assets/JoinScreen/founder.png"
            />
            <div class="flex flex-col items-start justify-start">
              <div
                class="self-stretch flex flex-col items-start justify-start py-0 pr-[34.599998474121094px] pl-0"
              >
                <div class="relative leading-[24px] font-semibold">
                  Nam Dinh
                </div>
              </div>
              <div
                class="flex flex-col items-start self-stretch justify-start text-www-paage-io-join-1920x1080-default-river-bed"
              >
                <div class="relative leading-[24px]">
                  <p class="m-0">Founder</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped></style>
